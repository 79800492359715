import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button.jsx"
import HeaderLinksExternal from "components/Header/HeaderLinksExternal.jsx"
import Parallax from "components/Parallax/Parallax.jsx"

import careersPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx"
import CareerPost from "Components/Sections/Career/CareerPost";

import { cleanText } from './utils';

// Sections for this page
import PublicationSection from "./Sections/PublicationSection.jsx"

const dashboardRoutes = []


class CareersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { jobs: [] };
    this.queryJobs = this.queryJobs.bind(this);
  }

  queryJobs() {
    const route = "https://db.galaxytherapeutics.com/wp-json/wp/v2/job";
    return fetch(route, {
      method: "GET"
    }).then(body => body.json());
  }

  componentDidMount() {
    this.queryJobs()
      .then(x => {
      // strip html tags and odd uri encoding
      x.forEach(el => {
        const { content: { rendered: body }, title: {rendered: title }} = el;
        el.content.rendered = cleanText(body);
        el.title.rendered = cleanText(title);
      });

      return x
    }).then(jobs => this.setState({ jobs }));
  }

  render() {
    const { classes, ...rest } = this.props
    const { jobs } = this.state;
    const careerPosts = jobs
      .filter(el => el.status === "publish")
      .map(post => {
        const { content: { rendered: postBody }, modified, id, title: { rendered: postTitle }} = post;
        return (
          <CareerPost 
            key={id}
            title={postTitle}
            body={decodeURIComponent(postBody)}
            date={new Date(modified).toDateString()}
          />
        );
      });

    return (
      <div>
        <Header
          color="white"
          routes={dashboardRoutes}
          brand="Galaxy Therapeutics"
          rightLinks={<HeaderLinksExternal />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white",
          }}
          {...rest}
        />
        <Parallax
          style={{ height: "400px" }}
          filter
          image={require("assets/img/galaxy_trees.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title}>Careers</h1>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer>
              {careerPosts}
            </GridContainer>
            <div style={{ height: "10px" }} />
          </div>
        </div>
        <div style={{ height: "10px" }} />
        <Footer />
      </div>
    )
  }
}

export default withStyles(careersPageStyle)(CareersPage)
