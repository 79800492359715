import React from "react"
import GridItem from "components/Grid/GridItem.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"

const CareerPost = ({ title, body, date }) => {
  return (
     <GridItem xs={12} sm={12} md={12}>  
      <Card>
        <CardBody>
          <h2
            style={{
              color: "black",
              // paddingLeft: "3rem",
              textAlign: "left",
            }}
          >
            {title}
          </h2>
          <p
            style={{
              color: "black",
              // paddingLeft: "3rem",
              textAlign: "left",
            }}
          >
            {body}
          </p>
          <p
            style={{
              // color: "black",
              // paddingLeft: "3rem",
              textAlign: "left",
            }}
          >
            Posted on {date}.
          </p>
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default CareerPost;
